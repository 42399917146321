/* Container Styling */
.summary-card {
  margin: 10px 0;
}

/* Vendor Name Styling */
.vendor-name {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;
}

/* Table Container for Responsiveness */
.table-container {
  overflow-x: auto;
  margin-top: 10px;
}

/* Table Styling */
.summary-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #ccc;
}

/* Table Header */
.summary-table thead {
  background-color: #f9f9f9;
}

.summary-table th {
  border: 1px solid #ccc;
  padding: 8px;
  font-weight: bold;
}

/* Table Rows */
.summary-table td {
  border: 1px solid #ccc;
  padding: 8px;
  word-wrap: break-word;
}

/* Alternate Row Colors */
.summary-table .even-row {
  background-color: #ffffff;
}

.summary-table .odd-row {
  background-color: #f5f5f5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .summary-table th,
  .summary-table td {
    font-size: 12px;
    padding: 6px;
  }

  .vendor-name {
    font-size: 14px;
  }
}
