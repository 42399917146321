@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.navbar-custom {
  background-color: #6c757d !important; /* Adjust this hex value to your preferred shade of gray */
}

.available-search {
  cursor: pointer;
  background: #d9c4a1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 10px;
  border-radius: 12px;
}

.available-search:hover {
  background: #cf949c;
  cursor: pointer;
}

.date-input {
  width: 250px;
  height: 50px;
  border-radius: 12px;
  font-size: 25px;
  text-align: center;
  border: 1px solid black;
  margin: 20px 20px;
  cursor: pointer;
}

.date-level {
  font-size: 25px;
  margin: 20px 20px;
}

.date-input input[type="date"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.Heads {
  text-transform: uppercase;
  color: #56a06c;
  text-align: center;
}

.image {
  inline-size: 80%;
  block-size: 80vh;
}
/* Loading ..................  */

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
  border-top: 16px solid #791615;
  border-bottom: 16px solid #000000;
  border-left: 16px solid #fff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Printing Page */

#report {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.toggle-switch {
  position: relative;
  width: 60px;
  height: 30px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-label::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-input:checked + .toggle-label {
  background-color: #4caf50;
}

.toggle-input:checked + .toggle-label::after {
  transform: translateX(30px);
}

/* RollOver */

.rollover-container {
  display: flex; /* Change to flex for horizontal alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  text-align: center;
  margin: 20px;
}

.box {
  border: 2px solid #007bff; /* Blue border */
  border-radius: 8px;
  padding: 20px;
  width: 200px; /* Adjusted width */
  margin: 10px; /* Space around boxes */
  background-color: #e7f0ff; /* Light blue background */
  transition: background-color 0.3s ease;
}

.box:hover {
  background-color: #cce5ff; /* Change background on hover */
}

.arrow {
  font-size: 2rem; /* Adjust size as needed */
  margin: 0 20px; /* Space around arrow */
  transition: transform 0.3s ease;
}

.arrow:hover {
  transform: scale(1.2); /* Scale up on hover */
}

ul {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Remove padding */
}

li {
  margin: 5px 0; /* Space between list items */
}

@media print {
  @page {
    margin: 0; /* Remove default page margins */
    size: auto; /* Ensure content uses the full page */
  }

  body {
    margin: 0;
    padding: 0;
  }

  .print-content {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    /* height: 100vh; */
    overflow: hidden; /* Prevent blank spaces */
  }
}
