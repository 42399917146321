@media print {
  body {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  .responsive-table {
    font-size: 14px; /* Default font size for print */
    width: 100%;
    border-collapse: collapse;
  }

  .responsive-table th,
  .responsive-table td {
    font-size: 14px; /* Default font size for print */
    padding: 6px;
    white-space: nowrap; /* Prevent text wrapping */
  }

  /* Reduce font size dynamically if the table has more than 3 rows */

  /* Allow table rows to break onto new pages if needed */
  .responsive-table tr {
    page-break-inside: avoid;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

  /* Ensure the container fits within the page */
  .container {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
}
